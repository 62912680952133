import React, { useRef, useEffect, useState, FC } from 'react'
import { PlayArrow, Pause, Download } from '@mui/icons-material'
import ResizeObserverPolyfill from 'resize-observer-polyfill';

interface WaveSurferComponentProps {
    audioUrl?: string | null
    borderStyle?: string
    borderColorClass?: string
    style?: 'large' | 'small' | 'landing'
    hasLoadingSpinner?: boolean
    waveformLoaded?: () => void
    download?: boolean
    errorMessage?: string | null
    job_status?: string | null
    currentlyPlaying?: string | null
    onPlayPause?: () => void
}

let WaveSurfer: any

const WaveSurferComponent: FC<WaveSurferComponentProps> = ({
    audioUrl = null,
    borderStyle = 'border',
    borderColorClass = 'border-gray-500',
    style = 'large',
    hasLoadingSpinner = false,
    waveformLoaded,
    download = true,
    errorMessage = null,
    job_status = null,
    currentlyPlaying = null,
    onPlayPause = () => {},
}) => {
    const waveSurferRef = useRef<any>(null)
    const waveformRef = useRef<HTMLDivElement>(null)
    const [isPlaying, setIsPlaying] = useState<boolean>(false)
    const [isAudioLoaded, setAudioLoaded] = useState<boolean>(false)
    const audioRef = useRef<HTMLAudioElement | null>(null) // Create a ref for the audio element, testing
    const [browserError, setBrowserError] = useState<string | null>(null);

    let svgSize: string = '30'
    let button_width: string = 'w-12'
    let button_height: string = 'h-12'
    let audio_height: string = 'h-20'
    let padding_width: string = '4'
    let bar_width: number = 5
    let rounded: string = 'rounded-lg'
    let waveform_padding = '4'
    let icon_size = 'text-lg lg:text-3xl'

    if (style == 'large') {
        button_width = 'w-12'
        button_height = 'h-12'
        icon_size = 'text-2xl lg:text-3xl'
        audio_height = 'h-20'
        padding_width = '4'
        bar_width = 5
    } else if (style == 'small') {
        button_width = 'w-8'
        button_height = 'h-8'
        audio_height = 'h-8'
        padding_width = '2'
        bar_width = 4
    } else if (style == 'landing') {
        button_width = 'w-10 lg:w-12'
        button_height = 'h-10 lg:h-12'
        icon_size = 'text-lg lg:text-xl'
        audio_height = 'h-12 lg:h-14'
        padding_width = '3'
        bar_width = 5
        rounded = 'rounded-3xl lg:rounded-3xl'
        waveform_padding = '2'
    }

    const handlePlay = () => setIsPlaying(true)
    const handlePause = () => setIsPlaying(false)
    const handleFinish = () => setIsPlaying(false)
    
    const checkResizeObserver = () => {
        if (typeof window !== 'undefined' && !window.ResizeObserver) {
            window.ResizeObserver = ResizeObserverPolyfill;
            setBrowserError('Your browser is very old and will not work with our site. Please update your browser.');
            return false
        }
        return true
    };


    const loadWS = async (newAudioUrl: string) => {
        if (!checkResizeObserver()) {
            return
        }

        
        if (audioRef.current) {
            audioRef.current = null
        }

        audioRef.current = new Audio()
        audioRef.current.src = newAudioUrl

        if (waveSurferRef.current) {
            waveSurferRef.current.destroy()
        }

        // await ensureResizeObserver()
        if (!WaveSurfer) {
            WaveSurfer = require('wavesurfer.js')
        }

        waveSurferRef.current = WaveSurfer.create({
            container: waveformRef.current,
            responsive: true,
            fillParent: true,
            normalize: true,
            height: 'auto',
            cursorColor: 'transparent',
            waveColor: 'gray',
            progressColor: 'white',
            barWidth: bar_width,
            barGap: 3,
            barRadius: 5,
            autoplay: false,
            media: audioRef.current,
        })

        waveSurferRef.current.on('play', handlePlay)
        waveSurferRef.current.on('pause', handlePause)
        waveSurferRef.current.on('finish', handleFinish)
        waveSurferRef.current.on('ready', handleAudioReady)
    }

    const handleAudioReady = () => {
        waveformLoaded?.()
        setAudioLoaded(true)
    }

    const killWS = () => {
        if (audioRef.current) {
            audioRef.current = null
        }

        if (waveSurferRef.current) {
            waveSurferRef.current.un('play', handlePlay)
            waveSurferRef.current.un('pause', handlePause)
            waveSurferRef.current.un('finish', handleFinish)
            waveSurferRef.current.un('ready', handleAudioReady)
            waveSurferRef.current.destroy()
        }
    }

    useEffect(() => {
        if (audioUrl) {
            loadNewAudio(audioUrl)
        } else {
            resetAudio()
        }
        return () => {
            resetAudio()
        }
    }, [audioUrl])

    useEffect(() => {
        if (waveSurferRef.current && audioUrl) {
            if (currentlyPlaying === audioUrl) {
                if (!isPlaying) {
                    waveSurferRef.current.play()
                    setIsPlaying(true)
                }
            } else if (isPlaying) {
                waveSurferRef.current.pause()
                setIsPlaying(false)
            }
        }
    }, [currentlyPlaying, audioUrl])

    const togglePlay = () => {
        if (waveSurferRef.current) {
            waveSurferRef.current.playPause()
            setIsPlaying(!isPlaying)
            onPlayPause()
        }
    }

    const resetAudio = () => {
        if (audioRef.current) {
            audioRef.current = null
        }
        if (waveSurferRef.current) {
            console.log('Reset')
            waveSurferRef.current.destroy()
            setAudioLoaded(false)
        }
    }

    const loadNewAudio = async (newAudioUrl: string | null) => {
        if (newAudioUrl) {
            console.log('Loading new audio: ', newAudioUrl)
            await loadWS(newAudioUrl)

            // waveformLoaded?.();
        } else {
            console.log('~ Did not load audio:')
            console.log('~ newAudioUrl: ', newAudioUrl)
            console.log('~ audioRef.current: ', audioRef.current)
        }
    }

    const playPauseButton = (
        <div className={`flex items-center justify-center ${button_width} mr-${padding_width}`}>
            <button
                onClick={togglePlay}
                disabled={!isAudioLoaded}
                className={`${
                    !isAudioLoaded ? 'pointer-events-none opacity-20' : ''
                } relative z-10 ${button_width} ${button_height} rounded-full border-2 border-gray-500 bg-transparent text-gray-300 hover:border-gray-300 hover:text-gray-100 focus:outline-none`}
            >
                <div className={`flex h-full w-full items-center justify-center ${icon_size}`}>{isPlaying ? <Pause fontSize="inherit" /> : <PlayArrow fontSize="inherit" />}</div>
            </button>
        </div>
    )

    const downloadButton = (
        <div
            id="download"
            className={`flex items-center justify-center bg-transparent ${borderStyle} ${borderColorClass} hover:border-gray-300 ${
                isAudioLoaded ? '' : 'pointer-events-none border-opacity-20 opacity-50 '
            } ml-2 rounded-full text-gray-300 hover:text-gray-100`}
        >
            <a type="button" download="test_name" href={audioUrl ? audioUrl : '#'}>
                <button
                    disabled={!isAudioLoaded}
                    // onClick={ }
                    className={`relative z-10 ${button_width} ${button_height} ${isAudioLoaded ? '' : 'opacity-50'} focus:outline-none`}
                >
                    <div className={`flex h-full w-full items-center justify-center ${icon_size}`}>
                        <Download fontSize="inherit" />
                    </div>
                </button>
            </a>
        </div>
    )

    const waveform = <div ref={waveformRef} hidden={!isAudioLoaded || !!errorMessage || !!browserError } className={`z-10 flex-grow ${audio_height} mr-${waveform_padding}`} />

    const noAudio = (
        <div
            className={`z-10 flex-grow ${audio_height} ${!isAudioLoaded && !hasLoadingSpinner && !errorMessage && !browserError ? 'flex' : 'hidden'} items-center justify-center text-sm
        opacity-50`}
        >
            No Audio Loaded
        </div>
    )

    const errorDisplay = (
        <div
            className={`z-10 flex-grow ${audio_height} ${errorMessage || browserError ? 'flex' : 'hidden'} items-center justify-center text-center text-xs
        font-semibold text-red-500 opacity-100 sm:text-sm`}
        >
            {errorMessage || browserError}
        </div>
    );

    const loading = (
        <div
            className={`z-10 flex-grow ${audio_height} ${hasLoadingSpinner && !errorMessage && !browserError ? 'flex flex-col' : 'hidden'} items-center justify-center text-sm
        opacity-50`}
        >
            {/* <CircularProgress className="mb-3" size="1.5rem" sx={{ color: '#DDDDDD' }} /> */}
            <span className="animate-pulse font-semibold">{job_status}</span>
        </div>
    )

    return (
        <div className="flex flex-row">
            <div
                id="waveplayer"
                className={`relative grow ${borderStyle} ${isAudioLoaded ? '' : 'border-opacity-50'} ${rounded} ${borderColorClass} bg-gray-900 px-${padding_width} z-20 flex items-center py-2`}
            >
                {playPauseButton}
                {waveform}
                {noAudio}
                {loading}
                {errorDisplay}
                {download && downloadButton}
            </div>
        </div>
    )
}

export default WaveSurferComponent
