import React, { useState, useEffect } from 'react'
import Image from 'next/image'
import { Artist } from '@/components/contexts/ArtistContext'
import DemoPlayer from '@/components/demo-player'
import { classNames } from '@/utils/helpers'

interface ArtistListProps {
    selectedArtist: Artist | null
    setSelectedArtist: React.Dispatch<React.SetStateAction<Artist | null>>
    demoAudio: HTMLAudioElement
    artists: Artist[] | null
}

const ArtistList = ({ selectedArtist, setSelectedArtist, demoAudio, artists }: ArtistListProps) => {
    const [selectedTags, setSelectedTags] = useState<string[]>([])
    const [allTags, setAllTags] = useState<string[]>([])

    const [playingUrl, setPlayingUrl] = useState<string | null>(null)

    useEffect(() => {
        if (artists) {
            const tags = ['male', 'female', 'singing', 'rap']
            setAllTags(tags)
        }
    }, [artists])

    const handleTagClick = (tag: string) => {
        setSelectedTags((prevTags) => {
            if (tag === 'male' && prevTags.includes('female')) {
                return prevTags.filter((t) => t !== 'female').concat(tag)
            } else if (tag === 'female' && prevTags.includes('male')) {
                return prevTags.filter((t) => t !== 'male').concat(tag)
            } else if (prevTags.includes(tag)) {
                return prevTags.filter((t) => t !== tag)
            } else {
                return [...prevTags, tag]
            }
        })
    }

    const handleArtistClick = (artist: Artist) => {
        setSelectedArtist(artist)
    }

    const filteredArtists = artists?.filter((artist) => selectedTags.length === 0 || selectedTags.every((tag) => artist.metadata.tags.includes(tag))) ?? []

    return (
        <div className="app-border relative z-20 flex min-w-full flex-col overflow-hidden rounded-md border bg-gradient-to-br from-gray-800 to-gray-950 text-left">
            <div className="sub-header-gradient app-border relative flex border-b border-opacity-80 px-4 py-3 sm:py-4">
                <div className="grow">
                    <div className="h8 mb-3 mr-4 inline">Filter by:</div>
                    <div className="mt-1 inline-flex flex-wrap gap-1 sm:mt-0">
                        {allTags.map((tag) => (
                            <span key={tag} className={`tag cursor-pointer select-none px-1.5 py-1 sm:px-2  ${selectedTags.includes(tag) ? 'tag-selected' : ''}`} onClick={() => handleTagClick(tag)}>
                                {tag}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
            <div className="relative grid grid-cols-1 gap-0.5 overflow-x-hidden overflow-y-scroll bg-opacity-70 lg:h-[60vh] xl:grid-cols-2">
                {filteredArtists.map((artist, index) => (
                    <div
                        key={index}
                        className={classNames(
                            'app-gradient-dark browser-item-bg relative z-0 flex cursor-pointer flex-wrap items-start lg:max-h-[25rem] xl:flex-nowrap',
                            selectedArtist === artist ? 'app-gradient-selected bg-opacity-70' : '',
                            filteredArtists.length <= 1 ? 'col-span-2' : '',
                        )}
                        onClick={() => handleArtistClick(artist)}
                    >
                        <div className="grid w-full grid-cols-3 flex-nowrap items-start sm:flex sm:h-full ">
                            <div className={`h-full shrink-0 basis-1/3 p-[1px] sm:h-full  xl:basis-1/2 ${selectedArtist === artist ? '' : ''}`}>
                                <Image
                                    src={process.env.NEXT_PUBLIC_IMAGE_CDN + artist.metadata.image}
                                    alt={artist.display_name}
                                    width={350}
                                    height={350}
                                    className="h-full w-full object-cover"
                                    placeholder="empty"
                                />
                            </div>
                            <div className="col-span-2 flex w-full basis-full flex-row flex-wrap justify-between gap-4 p-3 sm:h-full sm:basis-2/3 sm:p-4 xl:basis-1/2 xl:flex-col">
                                <div className=" flex w-full flex-col  gap-4">
                                    <div className="h7 font-opensans text-emphasis">
                                        <span>{artist.display_name}</span>
                                        {artist.metadata.new && <span className="ml-2 inline text-xs text-vai-highlight-v2 font-bold">New</span>}
                                    </div>

                                    <div className="tag-gap -mt-2 flex flex-wrap">
                                        {artist.metadata.tags.map((tag) => (
                                            <span key={tag} className="tag-list">
                                                {tag}
                                            </span>
                                        ))}
                                        {artist.tones && <span className="tag-list tag-list-tones">multi-style</span>}
                                    </div>

                                    <div className="h8 font-opensans">
                                        {artist.metadata.description}
                                        <div className="h8 mt-2 hidden font-opensans sm:block">
                                            <span className="gradient-text pr-1 font-bold">Great For:</span> {artist.metadata.summary}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex w-full flex-col justify-end">
                                    <div className="grid w-full grid-cols-2 flex-wrap items-end gap-y-1 sm:grid-cols-2">
                                        {artist.metadata.previews.map((demo, index) => (
                                            <DemoPlayer
                                                key={`${process.env.NEXT_PUBLIC_IMAGE_CDN + demo.url}`}
                                                genre={demo.genre}
                                                url={`${process.env.NEXT_PUBLIC_IMAGE_CDN + demo.url}`}
                                                demoAudio={demoAudio}
                                                stopPlaying={playingUrl != `${process.env.NEXT_PUBLIC_IMAGE_CDN + demo.url}`}
                                                onPlay={setPlayingUrl}
                                                index={index}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ArtistList
