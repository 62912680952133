import { Artist } from '@/components/contexts/ArtistContext'

export const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ')
}

export const formatDate = (date: Date): { full: string; short: string } => {
    const fullOptions: Intl.DateTimeFormatOptions = {
        dateStyle: 'short',
        timeStyle: 'short',
    }
    const shortOptions: Intl.DateTimeFormatOptions = {
        dateStyle: 'short',
    }

    return {
        full: new Intl.DateTimeFormat('en-US', fullOptions).format(date),
        short: new Intl.DateTimeFormat('en-US', shortOptions).format(date),
    }
}

/** Accent Level Maps */
const ACCENT_LEVEL_MAP: Record<string, number> = {
    off: 0.0,
    low: 0.33,
    medium: 0.5,
    high: 0.76,
}

export function convertAccentLevel(level: string): number {
    return ACCENT_LEVEL_MAP[level]
}

export function reverseAccentLevel(value: number): string | number {
    const accent = Object.keys(ACCENT_LEVEL_MAP).find((key) => ACCENT_LEVEL_MAP[key] === value)
    return accent || value
}

/** Volume Strength */
const VOLUME_STRENGTH_MAP: Record<string, number> = {
    off: 0.0,
    low: 0.25,
    medium: 0.4,
    high: 0.6,
}

export function convertVolumeStrength(level: string): number {
    return VOLUME_STRENGTH_MAP[level]
}

export function reverseVolumeStrength(value: number): string | number {
    const volume = Object.keys(VOLUME_STRENGTH_MAP).find((key) => VOLUME_STRENGTH_MAP[key] === value)
    return volume || value
}

/** Protect */
const PROTECT_MAP: Record<string, number> = {
    normal: 0.33,
    high: 0.15,
    maximum: 0.0,
}

export function convertProtect(level: string): number {
    return PROTECT_MAP[level]
}

export function reverseProtect(value: number): string | number {
    const protect = Object.keys(PROTECT_MAP).find((key) => PROTECT_MAP[key] === value)
    return protect || value
}

export function getSelectedArtistTones(selectedArtist: Artist | null): { [key: string]: string } {
    if (selectedArtist == null) {
        return {}
    }
    let tones: { [key: string]: string } = { default: 'Full Range' }
    selectedArtist.tones?.forEach((tone) => {
        tones[tone.label] = tone.label
    })
    return tones
}

export const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export const calcCredits = (duration: number) => {
    return Math.round((duration / 60) * 100) / 100
}
