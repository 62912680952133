import React, { useState, useEffect } from "react";
import { Pause, PlayArrow } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

interface DemoPlayerProps {
    genre: string;
    url: string;
    demoAudio: HTMLAudioElement;
    stopPlaying: boolean;
    onPlay: (url: string) => void;
    index: number;
}

const DemoPlayer = ({ genre, url, demoAudio, stopPlaying, onPlay, index }: DemoPlayerProps) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (stopPlaying) {
            setIsPlaying(false);
            setIsLoading(false);
        }
    }, [stopPlaying]);

    useEffect(() => {
        const handleEnded = () => {
            if (demoAudio.src === url) {
                setIsPlaying(false);
                setIsLoading(false);
            }
        };

        demoAudio.addEventListener('ended', handleEnded);
        return () => {
            demoAudio.removeEventListener('ended', handleEnded);
        };
    }, [demoAudio, url]);

    const togglePlay = () => {
        if (isPlaying) {
            demoAudio.pause();
            setIsPlaying(false);
            setIsLoading(false);
        } else {
            // Show loading state immediately
            setIsLoading(true);
            
            
            // Handle audio playback
            const playAudio = async () => {

                try {
                    if (demoAudio.src !== url) {
                        await demoAudio.pause();
                        demoAudio.src = url;
                    }
                    
                    onPlay(url);
                    demoAudio.currentTime = 0;
                    await demoAudio.play();
                    setIsPlaying(true);
                    setIsLoading(false);
                } catch (error) {
                    console.error('Error handling audio:', error);
                    setIsPlaying(false);
                    setIsLoading(false);
                }
            };

            playAudio();
        }
    };

    const playButton = <PlayArrow fontSize="inherit" className="" color="inherit"/>;
    const pauseButton = <Pause fontSize="inherit" color="inherit" className="" />;
    const loadingSpinner = <CircularProgress size={18} color="inherit" className="ml-1" />

    return (
        <div className="select-none flex flex-row w-full" onClick={togglePlay}>
            <div
                className={`text-gray-300 hover:text-gray-200 grow border-2 border-gray-600 p-1 rounded-lg
                flex items-center justify-center hover:bg-gray-700  hover:border-gray-500 sm:p-1
                          ${index % 2 == 0 && "mr-0.5"} ${index % 2 == 1 && "ml-0.5"} h-[35px] relative`}
            >
                <span className="text-[1.5rem] leading-[0] pr-0.5 left-0.5 absolute">
                    {isPlaying || isLoading ? pauseButton : playButton}
                    {/* { loadingSpinner } */}
                </span>
                <div className="w-4 shrink"/>
                <span className="h8 px-0 py-0 text-xs sm:text-sm text-center m-auto">{genre}</span>
                <div className="w-4"/>
            </div>
        </div>
    );
};

export default DemoPlayer;
